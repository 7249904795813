import { ConnectedComponent } from 'react-redux'
import ApiDate from 'utils/ApiDate'
import { AppConfig } from 'appConfig'
import { StepEnum } from './steps'
import { PAFormFields } from './components/register/RegistrationFormScene/RegistrationFormScene'

export interface InvalidatableCollection<T> {
  didInvalidate?: boolean
  isFetching: boolean
  items: T
  lastUpdated: number
}

export class ApiResponse<T> {
  ok!: boolean
  json!: () => T
}

export interface Address {
  address_line_1: string | null
  address_line_2: string | null
  city: string | null
  state: string | null
  zip: string | null
}

export interface Badge {
  id: number
  title: string
}

export interface Ballot extends Coordinates {
  address: string
  city: string
  election_id: number
  email: string
  first_name: string
  id: number
  last_name: string
  locale?: string
  notifications_opt_in: boolean
  party: Party | null
  party_id: number | null
  phone?: string
  pledge_reason: string
  polling_places_processed: boolean
  processed: boolean
  signature: string
  state: string
  street: string
  street_number: string
  voter_progress: VoterProgress
  zipcode: string
}

export interface BallotRequestValues {
  email: string
  ever_voted_in_person: boolean
  excuse: string
  first_time_absentee_voter: boolean
  four_digits: string
  mailing_city: string
  mailing_state: string
  mailing_street: string
  mailing_zip: string
  name: string
  party: string
  phone: string
  registered_to_vote_by_mail: boolean
  registration_city: string
  registration_state: string
  registration_street: string
  registration_zip: string
  signature: string
}

export interface VoterProgress {
  electionCenterSections: ElectionCenterSection[]
}

export interface Tile {
  available: boolean
  body?: string
  component: React.ElementType
  href?: string
  icon?: string[]
  sectionType: ElectionCenterSection
  title: string
  to?: string
}

export interface CustomTile {
  available?: boolean
  body?: string
  disableable?: boolean
  icon?: string[]
  imgUrl?: string
  key: string
  title: string
  url?: string
}

export type ElectionCenterSection =
  | (
      | 'register'
      | 'pledge'
      | 'ballot'
      | 'turnout'
      | 'address'
      | 'office_holders'
      | 'partners'
      | 'recruitment'
      | 'request_ballot'
      | 'slate'
      | 'explore_by_map'
      | 'meet_your_rep'
    )
  | CustomTile
  | string

export interface Candidacy {
  position_name: string
  row_order: number
  state: string
  position: Position
  election: Election
}

export type Degree =
  | 'Doctorate'
  | 'MD'
  | 'JD'
  | 'MBA'
  | "Master's"
  | "Bachelor's"
  | "Associate's"
  | 'Licensure'
  | 'Certificate'
  | 'High School Diploma'
  | 'GED'

export interface District {}

export interface Education {
  degree?: Degree
  grad_year: string
  major?: string
  school: string
  source_url?: string
}

export interface Experience {
  company: string
  end_year: string
  position: string
  start_year: string
  source_url?: string
}

export interface Tag {
  id: number
  tag_name: string
}

export interface MarkdownBlob {
  id: number
  locale: string
  markdown_blob: string
  markdown_type: string
}

export interface CandidateMarkdown extends MarkdownBlob {
  metadata_blob: MetadataContents
}

export interface MeasureMarkdown extends MarkdownBlob {
  metadata_blob: MetadataContents
}

export interface MetadataContents {
  author?: string
  description: string
  favicon?: string
  image: string
  keywords: string[]
  url: string
  rootUrl?: string
  title: string
}

export interface Geofences {
  name: string
  geofences_positions: any
}

export interface NormalizedPositionData {
  loading: boolean
  mapData: Geofences[]
  error: string | null
}

export interface CandidateUrl {
  id: number
  type: string
  url: string
}

export interface Person {
  first_name: string
  last_name: string
  middle_name?: string
  nickname?: string
  suffix?: string
  thumb_url: null | string
  urls: CandidateUrl[]
  withdrawn?: boolean
}

export interface Party {
  id: number
  name: string
  short_name: string
}

export interface Stance {
  candidate_id: number
  description: string
  id: number
  locale: string
  reference_url: string
  response_value: number
}

export interface Issue {
  id: number
  is_question: boolean
  key: string
  name: string
  question_text: string
  response_type: IssueResponseType
  stances: Stance[]
  response_maximum?: number
  row_order?: number
}

export type IssueResponseType =
  | 'check_or_x'
  | 'letter_grade'
  | 'ten_point_scale'
  | 'yes_no'

export interface Endorsement {
  color: string
  id: number
  organization_id: number
  logo_url: string
  name: string
  recommended: boolean | null
  row_order: number | null
  website_url: string
  issue_key: string
  issue_id: number
  issue_name: string
}

export interface Candidate extends Person {
  badges: Badge[]
  bar_association_evaluations: BarAssociationEvaluation[]
  bio_text: string
  candidacies: Candidacy[]
  check_or_x: CheckOrX[]
  education: Education[]
  election_day?: string
  endorsements: Endorsement[]
  experience: Experience[]
  id: number
  incumbent: boolean
  issues: Issue[]
  markdowns: CandidateMarkdown[]
  parties: Party[]
  party_name: string // returned on candidates included in position object from /positions
  running_mate?: Person
  slug: string
  tags: Tag[]
  withdrawn: boolean

  // Weird candidates in SEO directory
  candidate_id?: number
}

export type TermSpecificity = 'day' | 'time' | 'year' | 'month'

export interface CheckOrX {
  checkOrX: 'check' | 'x'
  field_description: string
  field_name: string
}

export interface BarAssociationEvaluation {
  evaluation: '0' | '1' | '2'
  bar_association_name: string
}

export interface Election extends Address {
  absentee_excuses_blob: string
  absentee_excuses_url: string
  absentee_url: string
  caucus: boolean
  deadline_drop_off: string
  deadline_mail_in: string
  deadline_mail_in_utc: string // We can switch the api to use UTC as the regular one after the switchover
  deadline_online_voter_registration: string
  deadline_request_ballot: string
  deadline_voter_registration: string
  deadline_voter_registration_in_person: string
  deadline_voter_registration_type: 'postmarked' | 'received'
  early_voting_starts_on: string
  early_voting_ends_on: string
  election_day: string
  enable_maptv_at: string
  default_time_zone: string
  hasRecall: boolean
  id: number
  id_requirements_url: string
  in_person_voting: boolean
  mail_in_must_be_postmarked_by: boolean
  name: string
  primary: boolean
  publish_at: string | null
  request_ballot_url: string
  time_notice: string
  request_ballot_must_be_postmarked_by: boolean
  ready_to_send_mail: boolean
  vg_publish_at: string | null
  voter_registration_url: string
  voter_check_registration_url: string | null
}

export type ElectionCoverage = 'statewide' | 'local' | 'consolidated'

export interface Dependency {
  listen_field: string | string[]
  show_hide_field: string
  reverse?: boolean
}

export interface ElectionForm {
  dependencies: Dependency[]
  id: number
  id_instructions: string
  is_id_required: boolean
  is_id_upload_enabled: boolean
  election_id: number
  excuses: Excuse[]
  fields: JsonSchemaFormFieldset
  field_names: FieldNameType[]
  field_order: string[]
  file_url: string
  required_fields: string[]
  valid_id_types: string
}

export interface FieldNameType {
  name: string
  maps_to: string
}

export interface Excuse {
  full_text: string
  href?: string | null
  key: string
}

export interface JsonSchemaFormField {
  br_required: boolean
  enum: string[]
  enumNames: string[]
  format: string
  properties: any // todo - make this better
  title: string
  type: string
}

export interface JsonSchemaFormFieldset {
  properties: { [key: string]: JsonSchemaFormField }
  title: string
  type: string
}

export interface OfficeHolder extends Person {
  candidate_id: number
  office_holder_id: number
  candidacy_id: number | null
  description: string
  email: string
  judicial: boolean
  next_election_year?: number
  party_name?: string
  party_short_name?: string
  phone: string
  position_id: number
  normalized_position_id: number
  position_name: string
  position_type: string
  row_order: number
  term_date_specificity: TermSpecificity
  start_at: string // timestamp
  end_at: string // timestamp
  central_phone: string
  level: PositionLevel
  office_phone: string
  other_phone: string
  primary_email: string
  has_unknown_boundaries: boolean
}

export interface County {
  accepts_digital_signature: boolean
  address_line_1: string
  address_line_2: string
  contact_email: string
  city: string
  email: string
  id: number
  geoid: string
  name: string
  population: number
  slug: string
  state: string
  zip: string
}

export interface UsState {
  counties: County[]
  elections: Election[]
  name: string
  registration_options?: RegistrationOption[]
  short_name: string
  secretary_of_state_url: string
  registration_url?: string
}

export type RegistrationChannel =
  | 'online'
  | 'by_form'
  | 'election_office'
  | 'same_day_early'
  | 'same_day_election'
  | 'no_registration'
  | 'api'
export type RegistrationPostmarkedReceived = 'postmarked' | 'received'
export interface RegistrationOption {
  channel: RegistrationChannel
  is_preregistration: boolean
  documents: string[]
  eligibility: string[]
  safest_registration_deadline_in_days: number
  postmarked_or_received: RegistrationPostmarkedReceived | null
}

export interface Selection {
  candidate_id: number | null
  id: number
  measure_id: number | null
  position_id: number | null
  rank: number
  yes_no: string | null
}

export interface Coordinates {
  lat: number
  lng: number
}

export interface PollingPlace extends Coordinates, Address {
  ballot_drop_off: boolean
  formatted_address: string
  id: number
  in_person_voting: boolean
  name: string
  polling_days: PollingDay[]
  hours?: PollingDay[] // The API was set up to use it this way for polling place search
  party_id?: number
  party_name?: string
}

export interface PollingDay {
  ballot_drop_off: boolean
  close_at: string
  date: string
  early_voting: boolean
  id: number
  in_person_voting: boolean
  open_at: string
  timezone: string
}

export interface Auth {
  isSignedIn: boolean
  user: User
  error?: string
}

export interface User {
  ballot_id: number
  ballot_user_id: number
  engine_token: string
}

export interface Measure {
  id: number
  has_unknown_boundaries: boolean
  image_url?: string
  markdowns: MeasureMarkdown[]
  name?: string
  slug: string
  summary?: string
  title?: string
}

export interface Party {
  color_primary?: string
  id: number
  name: string
  short_name: string
  election_id?: number
}

export interface PositionMarkdown extends MarkdownBlob {}
export type PositionLevel =
  | 'local'
  | 'county'
  | 'state'
  | 'city'
  | 'federal'
  | 'regional'
export interface Position {
  candidacies?: Candidate[] // /ballot/positions returns candidacies
  candidates?: Candidate[] // /positions returns candidates
  description: string
  eligibility_requirements?: string
  employment_type?: 'Full Time' | 'Part Time'
  general_filing_end?: string
  id: number
  is_primary: boolean // determined by position-election but stored on position when passed from API
  is_rcv_voting: boolean
  is_recall: boolean // determined by position-election but stored on position when passed from API
  judicial: boolean
  level: PositionLevel
  markdowns: PositionMarkdown[]
  name: string
  paperwork_instructions?: string
  partisan_type?: 'partisan' | 'nonpartisan' | 'partisan for primary only'
  primary_filing_end?: string
  retention: boolean
  row_order: number
  salary?: string
  selections_allowed: number
  slug: string
  tier: number
  timestamp: string
  sub_area_name?: string
  sub_area_value?: string
  is_running_mate?: boolean
  running_mate_starting_in?: 'general' | 'primary'

  // Weird positions in directory
  position_id?: number
}

// fields from TargetSmart https://docs.targetsmart.com/developers/tsapis/v2/voter/voter-registration-check.html
export interface RegistrationCheckRequest {
  first_name: string
  last_name: string
  street_number?: string
  street_name?: string
  city?: string
  state: string
  zip_code?: string //numbers only
  age?: number
  dob?: string // in form 'YYYYMMDD'
  phone?: string
  email?: string
  unparsed_full_address?: string
}

export interface RegistrationCheckResponse {
  too_many: boolean // true if more than one potential match was found, false otherwise
  parsed_address?: JSON // If unparsed_full_address is given, this parameter provides the corresponding parsed address in JSON format, or an empty object if the address was not parsable
  result: boolean
  result_set: TargetSmartVoter[]
}

export interface TargetSmartVoter {
  'vb.voterid': string // The TargetSmart id
  'vb.voterbase_id': string // The id from the state or other EA
}

export interface SharedBallotSelection {
  candidate: Candidate | null
  id: number
  measure: Measure | null
  position: Position | null
  rank: number | null
  selection_comment: string | null
  yes_no: string | null
}

export interface SharedBallot {
  user: { bio: string | null; name: string | null }
  selections: SharedBallotSelection[]
}

export interface Subscription {
  reminderEmail?: string
  reminderPhone?: string
  campaignTypes: string[]
}

export interface VotingPlan {
  ballot_id: number
  drop_off: boolean
  email: string | null
  in_person: boolean
  locale: string
  phone: string | null
  polling_place_id: number | null
  polling_place: PollingPlace
  vote_at: string | null
}

export interface Lead {
  email: string
}

export interface Place {
  accepts_digital_signature: boolean
  automatic_mail_ballots: boolean
  automatic_mail_applications: boolean
  id: number
  geo_id: string
  mtfcc: string
  name: string
  polling_place_url: string | null
  ready_to_send_mail: boolean
  secretary_of_state_url: string | null
  slug: string | null
  track_ballot_url: string | null
  voter_address_line_1: string | null
  voter_address_line_2: string | null
  voter_city: string | null
  voter_state: string | null
  voter_zip: string | null
  voter_email: string | null
  voter_check_registration_url: string
  voter_registration_url: string
  vbm_accepts_form_email: boolean
  vbm_receiver: boolean
  vbm_portal_url: string | null
}

export interface SubscriptionCall {
  callCompleted: boolean
}

export interface PositionCategory {
  icon: string[]
  key: string
  maxRowOrder: number
  minRowOrder: number
}

export interface IssueCategory {
  key: string
  id: number
  items: IssueCategoryItem[]
  name: string
  question_text?: string
}

export type IssueCategoryItem = Stance | Endorsement

export type Item = {
  description?: string
  level?: string
  id: string | undefined
  linkTarget?: string
  name: string
  person?: Person
  subtitle?: string
  showHeadshot: boolean
  showInitial: boolean
  warning?: boolean
}

export type ButtonVariantType =
  | 'primary'
  | 'default'
  | 'danger' // danger needs new color
  | 'link' // potentially change to a different element type
  | 'black'
  | 'panel'
  | 'white'
  | 'success' // remove success

export type ButtonSizeType = 'sm' | 'md' | 'lg'

export interface OptionType {
  label: string
  value: string
}

export type ReminderChannelType = 'email' | 'phone'
export type ReminderChannelCollection = {
  [key in ReminderChannelType]: ReminderChannel
}
export interface ReminderChannel {
  isValid: boolean | null
  value: string
}

export interface StoreState {
  appConfig: AppConfig
  auth: Auth
  authModalIsOpen: boolean
  availableElection: boolean
  ballot: Ballot
  ballotPlaces: Place[]
  browser: any
  candidatesById: { items: { [id: number]: Candidate }; isFetching: boolean }
  candidatesByPosition: {
    [key: number]: InvalidatableCollection<Candidate[]>
  }
  currentCandidate: Candidate
  currentIssue: Issue
  currentMeasure: Measure
  currentPosition: Position
  debug: boolean
  election: Election
  electionProgression: 'early' | 'election_day' | 'closed'
  elections: Election[]
  electionCenter: ElectionCenterState
  endorsedCandidates: Candidate[]
  fetchCounter: number
  form: ElectionForm[]
  filters: Filters
  issuesByPosition: {
    [key: number]: InvalidatableCollection<Issue[]>
  }
  isTimeTraveling?: boolean
  lead?: Lead
  mapResults: NormalizedPositionData
  measures: Measure[]
  maptvSelections: MAPTVSelections
  officeHolders: {
    officeHolders: OfficeHolder[]
    partyLeaders: OfficeHolder[]
    electedOfficials: OfficeHolder[]
    isFetching: boolean
    errorMessage?: string
  }
  parties: Party[]
  pingCount: number
  pollingDays: PollingDay[]
  pollingPlaces: PollingPlace[]
  positions: Position[]
  positionsByCounty: {
    [key: string]: InvalidatableCollection<Position[]>
  }
  positionsByState: {
    [key: string]: InvalidatableCollection<Position[]>
  }
  recruitmentSelections: RecruitmentSelections
  registration: RegistrationData
  reminderChannels: ReminderChannelCollection
  requestBallotSelections: RequestBallotSelections
  searchResults: SearchResults
  selections: InvalidatableCollection<Selection[]>
  sendEmail: MeetYourRepEmailState
  showVoterGuideAlert: boolean
  showGlobalAlert: boolean
  showLeadBar: boolean
  showShareableBallotBanner: boolean
  showSignUpPrompt: boolean
  states: {
    [key: string]: { isFetching: boolean; items: UsState; lastUpdated: number }
  }
  stepStack: StepStack
  subscriptionCall: SubscriptionCall
  usStates: UsState[]
  vbmReceiver: Place | null
  votingPlan: VotingPlan
}

export type StepStackPath = 'check_registration' | 'request_ballot' | 'm'

export type StepStack = {
  [key in StepStackPath]: StepEnum[]
}

export interface ElectionCenterState {
  addressModalRedirect?: string
  completedSections: ElectionCenterSection[]
  currentSection?: ElectionCenterSection
  showAddressModal: boolean
}

export interface StepComponent {
  component: ConnectedComponent<any, any>
  id: StepEnum
  when(state: StoreState): boolean | undefined
}

export interface SearchResults {
  candidates: Candidate[]
  positions: Position[]
  searching: boolean // query has been sent but waiting for results
  searchInitiated: boolean // search dropdown is open
  searchResults: any[]
  searchTerm: string
  selectedPosition?: Position
}

export interface PositionCandidatesOptions {
  include_all_endorsements?: '1' | null
  include_uncertified_candidates?: '1' | null
  check_or_x?: '1' | null
}

export interface MAPTVSelections {
  electionHasCaucus: boolean
  pollingDays: PollingDay[]
  hasBallot?: boolean
  needToRequestBallot?: boolean
  planStarted: boolean
  selectedDateTime?: ApiDate
  selectedFlow?: PlanFlowOption
  selectedMailFlow?: MailFlowOption
  selectedPollingDay?: PollingDay
  selectedPollingPlace?: PollingPlace
}

export interface RecruitmentSelections {
  positions: Position[]
  values: {
    address: string
    email: string
    year: string
  } | null
}

export interface RegistrationData {
  error?: string
  externalApiCallId?: number
  formData?: PAFormFields
  isFetching: boolean
  paAPISetup: PaAPISetup
  pollCount: number
  regRequestStatus?: 'failed' | 'succeeded' | 'created'
  regRequestBody?: string
  paApiForm?: PAFormFields
}

export interface RequestBallotSelections {
  absenteeBallotRequestForm?: any
  acquiredForm: boolean
  checkingRegistration: boolean
  checkRegistrationUrl: string | null
  communication: VBMContactInfo
  dismissedAutomaticVBMAlert: boolean
  formError?: string
  isAttemptingFormSubmit: boolean | null
  isFormFilled: boolean
  isRegistered?: string
  printBallotRequestSubmitted: boolean
  requestingBallot: boolean
  requestStarted: boolean
  returnStarted: boolean
  registration?: RegistrationCheckResponse
  registrationFailedOption?: RegistrationFailedOption
  registrationUrl: string | null
  requestBallotPortalUrl: string | null
  requestInPersonReminders: boolean
  showIdUploadStep: boolean
  showPrintBallotRequestStep: boolean
  uploadId?: string
  useFilledForm: boolean
  verificationRequestData: VerificationRequestData | null
  voterRegistrationMethod?: RegistrationChannel
}

export interface VerificationRequestData {
  first_name: string
  last_name: string
  date_of_birth?: string
  registration: RegistrationCheckResponse | undefined
  registrationFailedOption: RegistrationFailedOption | undefined
  showPrintBallotRequestStep: boolean | undefined
}

export type RegistrationFailedOption =
  | 'Help me register.'
  | 'Help me double check.'
  | "I'm registered"

export interface VBMContactInfo {
  email?: string
  phone?: string
  send_to_customer: boolean
}

export interface Filter {
  label?: string
  onFilterClick(key: string): void
  type: null | string
  value?: boolean | object | string | null
}

export interface FilterFormValues {
  levels: { [key: string]: boolean }
  name: string
  state: string
  tags: { [key: string]: boolean }
}

export interface AddressFormValues {
  address: string
  first_name: string
  last_name: string
  locale: string
  phone: string
  email: string
  notifications_opt_in: boolean
  lat?: string
  lng?: string
}

export interface Filters extends FilterFormValues {
  empty: boolean
}

export type PlanFlowOption = 'by-mail' | 'in-person' | 'caucus'

export type MailFlowOption = 'drop-off' | 'mail-in'

export type MainDetailOption = 'main' | 'detail'

export interface ReviewSelectionType extends Selection {
  candidate?: Candidate | null
}

export interface MeasureWithSelections extends Measure {
  selection: ReviewSelectionType | undefined
}

export interface PositionWithSelections extends Position {
  selections: ReviewSelectionType[]
}

export interface PollEvent {
  ballot_drop_off: boolean
  close_at: string
  date: string
  early_voting: boolean
  id: number
  in_person_voting: boolean
  open_at: string
  timezone: string
}

export interface PaAPISetup {
  assistanceDeclaration?: string
  assistanceTypeOptions: string[]
  countyOptions: string[]
  municipalityOptions: string[]
  raceOptions: string[]
  sexOptions: string[]
  suffixOptions: string[]
  unitTypeOptions: string[]
  voterDeclaration?: string
}

export interface CalendarEvent {
  date: string
  event: PollEvent
  outOfBounds: boolean
  onDateSelect: {
    (pollingDay: PollingDay): void
  }
  past: boolean
  checked: boolean
  isAnyChecked: string | undefined
  dataTestId: string
}

export type MeetYourRepNav =
  | 'home'
  | 'reps'
  | 'buildAgenda'
  | 'agendaSummary'
  | 'schedule'
  | 'finish'

export type MeetYourRepAgenda = {
  intro: string
  discussionPoints: string[]
  additionalNotes: string
}

export type MeetYourRepTemplateSection = { heading: string; content: string }

export type MeetYourRepEmailState = { message: string } | null

export type MeetYourRepEmailBody = {
  agendaIntro?: string
  agendaDiscussionPoints?: string[]
  agendaAdditionalNotes?: string
  contactFormTemplate?: MeetYourRepTemplateSection[]
  emailTemplate?: MeetYourRepTemplateSection[]
  phoneScript?: string
  phoneNumber?: string
  emailAddress?: string
  contactFormUrl?: string
  tenantName: string
  officeHolder: string
  homeUrl: string
}

export type MeetYourRepContactOption = 'contactForm' | 'email' | 'phone'
